import profile1 from "../assets/profile1.png";
import profile2 from "../assets/profile2.png";
import profile3 from "../assets/Honeyview_profile3.png";
import profile4 from "../assets/Honeyview_profile4.png";
import profile5 from "../assets/Honeyview_profile5.png";
import profile6 from "../assets/profile6.png";

export const TestimonialsData = [
  {
    image: profile3,
    comment:
      " Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    name: "Jang Yoon-ju",
  },
  {
    image: profile2,
    comment:
      " Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    name: "Alex Hormonzi",
  },
  {
    image: profile1,
    comment:
      " Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    name: "Natasha Ashton",
  },
  {
    image: profile4,
    comment:
      " Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    name: "John Alendon",
  },
  {
    image: profile6,
    comment:
      " Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    name: "emmily",
  },
  {
    image: profile5,
    comment:
      " Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    name: "Emma Kidman",
  },
];
